REPORTS = {
	init: function() {
		$("#report_quicksight_id").change(function(){
			if($("#report_name").val() === '') {
				$("#report_name").val($(this).find("option:selected").text());
			}
		})

		//var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		//var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
		//	return new bootstrap.Tooltip(tooltipTriggerEl);
		//});
	}
}

$(document).on('turbolinks:load', REPORTS.init);
