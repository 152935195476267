import $ from 'jquery';

function initChanges() {
  $(document).off('click', '.operator').on('click', '.operator', function() {
    this.textContent = this.textContent.trim() === 'AND' ? 'OR' : 'AND';
  });

  $('.draggable-item').on('dragstart', function(e) {
    draggedField = e.target;
    $(this).css('opacity', '0.5');
  });

  $('.draggable-item').on('dragend', function(e) {
    $(this).css('opacity', '1');
  });

  $('#query-builder').on('dragover', function(e) {
    e.preventDefault();
  });

  let draggedField = null;

  $('.draggable-item')
    .on('dragstart', function(event) {
      draggedField = this;
      $(this).css('opacity', '0.5');
    })
    .on('dragend', function(event) {
      $(this).css('opacity', '1');
    });

  $('#query-builder').on('drop', function(event) {
    event.preventDefault();

    const fieldType = $(draggedField).attr('data-type');
    const fieldLabel = $(draggedField).attr('data-label');
    $.ajax({
      url: '/data_park_filters/new',
      type: 'GET',
      data: {
        field_type: fieldType,
        field_label: fieldLabel
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      dataType: 'script',
      success: function() {
        console.log('Filter box added successfully!');
      },
      error: function(xhr, status, error) {
        console.error('Error fetching filter box:', error);
      }
    });
  });

  $(document).on('submit', '#data-park-form', function (e) {
    e.preventDefault();
    $('#query-json').val(exportQueryBuilderToJSON());

    // Add clicked button's name/value to the form
    const clickedButton = $(document.activeElement);
    if (clickedButton.attr('name') && clickedButton.attr('value')) {
      $('<input>').attr({
        type: 'hidden',
        name: clickedButton.attr('name'),
        value: clickedButton.attr('value'),
      }).appendTo(this);
    }

    this.submit();
  });
}

function removeFilterBoxElements(element) {
  var parent = element.parentElement;
  if (parent) {
    if (parent.previousElementSibling && parent.previousElementSibling.classList.contains('operator')) {
      parent.previousElementSibling.remove();
    }
    if (parent.nextElementSibling && parent.nextElementSibling.classList.contains('operator') && !parent.previousElementSibling ) {
      parent.nextElementSibling.remove();
    }
    parent.remove();
  }
  if($('.query-box').length == 1){
    $('.operator').remove()
  }
}

function exportQueryBuilderToJSON() {
  const $queryBuilder = $('#query-builder');
  const $queryBoxes = $queryBuilder.find('.query-box');
  const $operators = $queryBuilder.find('.operator');
  let queries = [];
  let operatorIndex = 0;

  $queryBoxes.each(function () {
    const $box = $(this);
    const label = $box.find('strong').text().trim();
    const comparator = $box.find('.comparator-select').val() || '';
    const $valueInput = $box.find('.value-input');
    let value;

    if ($valueInput.is('select')) {
      value = $valueInput.val();
    } else if ($valueInput.is('input')) {
      value = $valueInput.val();
    }

    queries.push({
      field: label,
      comparator: comparator,
      value: value,
      condition: $operators.eq(operatorIndex).text().trim() || null,
    });

    if ($operators.eq(operatorIndex).length) {
      operatorIndex++;
    }
  });

  return JSON.stringify(queries, null, 2);
}

// $(document).on('click', '#save-query-builder', function () {
//   const queryJSON = exportQueryBuilderToJSON();
//   const title = $('#data_park_title').val();
//   const dataParkId = $(this).data('id');

//   if (typeof dataParkId !== 'undefined') {
//     var url = `/data_parks/${dataParkId}`;
//     var method = 'PATCH';
//   }else{
//     var url = `/data_parks`;
//     var method = 'POST';
//   }

//   $.ajax({
//     url: url,
//     type: method,
//     data: {
//       data_park: {
//         json_data: queryJSON,
//         title: title
//       }
//     },
//     headers: {
//       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
//     },
//     success: function (response) {
//       console.log('Query saved successfully:', response);
//       alert('Query saved successfully!');
//     },
//     error: function (xhr, status, error) {
//       console.error('Error saving query:', error);
//       alert('Failed to save query.');
//     }
//   });
// });

$(document).on('turbolinks:load', initChanges);
window.removeFilterBoxElements = removeFilterBoxElements;
